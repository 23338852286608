<template>
  <div class="container-profile">
    <div class="profileBanner"></div>
    <div class="row">
      <div
        class="col-3 mr-auto profile-card profile-user-info d-flex flex-column justify-content-center align-items-center"
      >
        <div class="fs-65 mb-2">
          <font-awesome-icon icon="fa-solid fa-user-circle" />
        </div>
        <div class="fs-18 mb-2">
          <span class="text-bold uppercase"
            >{{ employee?.firstname }} {{ employee?.lastname }}</span
          >
        </div>
        <div class="mb-2">
          <span>{{ employee?.role?.name }}</span>
        </div>
        <div>
          <span>{{ employee?.company?.name }}</span>
        </div>
      </div>

      <div class="col-4 mr-auto d-flex flex-column profile-card">
        <div class="row p-3">
          <div class="uppercase fs-14 text-grey">Profile</div>
        </div>

        <div class="row mt-4 mb-4 fs-14">
          <div class="col-6 d-flex flex-column">
            <span class="text-blue fs-11 uppercase">Role</span>
            <span v-if="employee?.role?.name">{{ employee?.role?.name }}</span>
            <span v-else>No role assigned</span>
          </div>
          <div class="col-6 d-flex flex-column">
            <span class="text-blue fs-11 uppercase">Role description</span>
            <span v-if="employee?.role?.description">{{
              employee?.role?.description
            }}</span>
            <span v-else>No role description available</span>
          </div>
        </div>

        <div class="row mb-4 fs-14">
          <div class="col-6 d-flex flex-column">
            <span class="text-blue fs-11 uppercase">email</span>
            <span v-if="employee?.email">{{ employee?.email }}</span>
            <span v-else>No email adress available</span>
          </div>
          <div class="col-6 d-flex flex-column">
            <span class="text-blue fs-11 uppercase">phone</span>
            <span v-if="employee?.phone">{{ employee?.phone }}</span>
            <span v-else>No phone number available</span>
          </div>
        </div>

        <div class="row mb-auto fs-14">
          <div class="col-12 d-flex flex-column">
            <span class="text-blue fs-11 uppercase">position</span>
            <span v-if="employee?.position">{{ employee?.position }}</span>
            <span v-else>No position assigned</span>
          </div>
        </div>
      </div>

      <div
        class="col-4 mr-auto d-flex flex-column profile-card"
        v-if="employee?.company"
      >
        <div class="row p-3">
          <div class="uppercase fs-14 text-grey">Company</div>
        </div>

        <div class="row mt-4 mb-4 fs-14">
          <div class="col-12 d-flex flex-column">
            <span class="text-blue fs-11 uppercase">Name</span>
            <span v-if="employee?.company?.name">{{
              employee?.company?.name
            }}</span>
            <span v-else>No company name available</span>
          </div>
        </div>

        <div class="row mb-4 fs-14">
          <div class="col-6 d-flex flex-column">
            <span class="text-blue fs-11 uppercase">email</span>
            <span v-if="employee?.company?.email">{{
              employee?.company?.email
            }}</span>
            <span v-else>No company email adress available</span>
          </div>
          <div class="col-6 d-flex flex-column">
            <span class="text-blue fs-11 uppercase">phone</span>
            <span v-if="employee?.company?.phone">{{
              employee?.company?.phone
            }}</span>
            <span v-else>No company phone number available</span>
          </div>
        </div>

        <div class="row mb-auto fs-14">
          <div class="col-6 d-flex flex-column">
            <span class="text-blue fs-11 uppercase">address</span>
            <span v-if="employee?.company?.address">{{
              employee?.company?.address
            }}</span>
            <span v-else>No company address available</span>
          </div>
          <div class="col-6 d-flex flex-column">
            <span class="text-blue fs-11 uppercase">website</span>
            <a
              v-if="employee?.company?.website"
              :href="employee?.company?.website"
              >{{ employee?.company?.website }}</a
            >
            <span v-else>No company website available</span>
          </div>
        </div>
      </div>

      <div class="col-4 mr-auto d-flex flex-column profile-card" v-else>
        <div class="row p-3">
          <div class="uppercase fs-14 text-grey">No company assigned</div>
        </div>
      </div>
    </div>
  </div>
</template>
	  
	  <script>
import Api from "@/Api";
import store from "@/store";

import User from "@/models/User";

export default {
  name: "Employee",
  components: {},
  mixins: [],
  store,
  metaInfo() {
    return {
      titleTemplate: () => {
        return "";
      },
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      employee: {
        firstname: null,
        lastname: null,
        company: {
          name: null,
          address: null,
          phone: null,
          email: null,
          website: null,
        },
        role: { name: null, description: null },
        position: null,
        email: null,
        phone: null,
      },
    };
  },
  computed: {
    user: {
      get() {
        if (this.$store.state.user) return this.$store.state.user;
        else {
          return {
            user: {
              firstname: null,
              lastname: null,
              company: {
                name: null,
                address: null,
                phone: null,
                email: null,
                website: null,
              },
              role: { name: null, description: null },
              position: null,
              email: null,
              phone: null,
            },
          };
        }
      },
    },
  },
  created() {
    this.getEmployee();
  },
  mounted() {},
  methods: {
    getEmployee() {
      Api.get("employee/" + this.id).then((response) => {
        this.employee = response?.data;
      });
    },
  },
};
</script>
	  
	  <style lang="scss">
</style>
	  